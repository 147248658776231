<template>
  <div ref="orderIndexRef"
       class='order-index'>
    <a-form layout="inline"
            :model="searchForm">
      <a-form-item>
        <a-radio-group v-model:value="searchForm.orderStatus"
                       button-style="solid"
                       @change="ChangeOrderStatus">
          <a-radio-button value="">全部</a-radio-button>
          <a-radio-button value="created">待支付</a-radio-button>
          <a-radio-button value="paid">已支付</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <div class="my-12"
           v-if="!noSearch">
        <a-form-item label="客户姓名">
          <a-input v-model:value="searchForm.customerRealName"
                   placeholder="请输入"
                   @change="searchOrder"
                   style="width: 160px" />
        </a-form-item>
        <a-form-item label="客户手机号">
          <a-input v-model:value="searchForm.customerMobile"
                   placeholder="请输入"
                   @change="searchOrder"
                   style="width: 160px" />
        </a-form-item>
        <a-form-item v-if="role !=='ROLE_STAFF'"
                     label="员工姓名">
          <a-input v-model:value="searchForm.staffName"
                   placeholder="请输入"
                   @change="searchOrder"
                   style="width: 160px" />
        </a-form-item>
        <a-form-item class="mr24"
                     label="成交时间">
          <a-range-picker v-model:value="timeValue"
                          format="YYYY-MM-DD"
                          style="width: 280px"
                          @change="handleSetectTime" />
        </a-form-item>
      </div>
    </a-form>

    <div class="is-flex pb16 gray-darker"
         :style="{justifyContent: 'space-between'}">
      <p>
        累计订单数 <span :style="{color: '#FBA64D'}">{{ pagination.total }}</span> 笔，累计金额 <span class="color-danger">{{sumAmount}}</span> 元
      </p>
      <a-button v-if="!noSearch"
                class="border-radius"
                type="primary"
                :style="{width: '116px'}"
                @click="exportOrder">
        <DownloadOutlined />导出
      </a-button>
    </div>

    <a-table :columns="columns"
             :data-source="orders"
             :pagination="pagination"
             :scroll="{ x: '2000px', y: '100vh' }"
             rowKey="id"
             @change="onPaginationChange"
             :loading="tableLoading">

      <template #customer="{ record }">
        <span>{{record.customerName}}</span>
        <span class="name-suffix__wechat"
              v-if="record.customerType === userTypeEnum.WECHAT">@微信</span>
        <span class="name-suffix__corp"
              v-if="record.customerType === userTypeEnum.CORP">@{{record.corpName}}</span>
        <p class="mv4">姓名：{{record.customerRealName}}</p>
        <p>手机号：{{record.customerMobile}}</p>
      </template>

      <template #staffWorkWechatThirdAppUserId="{ text }">
        <template v-if="text">
          <div v-is="'ww-open-data'"
               class="ww-open-data"
               type="userName"
               :openid="text"></div>
        </template>

        <template v-else>
          -
        </template>
      </template>

      <template #status="{ record }">
        <span v-if="record.orderStatus === 'created'">待支付</span>
        <span v-else-if="['paid', 'success'].includes(record.orderStatus)">已支付</span>
      </template>

      <template #protocol="{ record }">
        <span class="color-primary text-overflow cursor-pointer"
              @click="openProtocolModal(record.protocolId)">{{record.protocolName}}</span>
      </template>

      <template #createdTime="{ text }">
        {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
      </template>

      <template #payTime="{ text }">
        {{ text ? $f.datetime(text , 'YYYY-MM-DD HH:mm') : ''}}
      </template>

      <template #action="{ record }">
        <a-button type="link"
                  @click="showOrderDetail(record.id)">查看</a-button>

        <a-button class="mh8"
                  type="link"
                  @click="openEditRemarkModal(record)">备注</a-button>

        <a-button class="gray"
                  type="link"
                  v-if="role === 'ROLE_ADMIN' && record.orderStatus === 'created'"
                  @click="deleteItem = record; deleteModalVisible = true">删除</a-button>
      </template>
    </a-table>

    <order-detail v-model:visible="
                           orderDetailVisible"
                  :orderDetail="orderDetail">
    </order-detail>

    <a-modal v-model:visible="editRemarkVisible"
             class="edit-remark"
             title="编辑备注"
             :width="600"
             :confirm-loading="confirmLoading"
             @ok="editRemark">
      <a-form-item label="备注"
                   name="remark"
                   :colon="false">
        <a-textarea v-model:value="editRemarkInfo.remark"
                    placeholder="请输入备注"
                    maxlength="100"
                    showCount></a-textarea>
      </a-form-item>

    </a-modal>

    <a-modal v-model:visible="protocolModalVisible"
             class="protocol-modal"
             title="协议详情"
             :width="600"
             :getContainer="() => orderIndexRef"
             :footer="null">
      <div class="pb24 is-flex">
        <div class="protocol__label">协议名称:</div>
        <div class="protocol__value">{{protocolDetail.name}}</div>
      </div>
      <div class="pb24 is-flex">
        <div class="protocol__label">协议内容:</div>
        <div class="protocol-content"
             v-html="protocolDetail.protocol"></div>
      </div>
    </a-modal>

    <delete-modal v-model:visible="deleteModalVisible"
                  title="删除订单"
                  desc="订单删除后无法撤销操作，确认删除订单吗？"
                  :delete-item="deleteItem"
                  @delete="deleteOrder">
    </delete-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { ref, reactive, watch } from "vue";
import { Radio, DatePicker } from "ant-design-vue";
import { DownloadOutlined } from "@ant-design/icons-vue";
import dayjs from "dayjs";

import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";

import orderApi from "@/service/api/order";
import OrderDetail from "./OrderDetail.vue";
import DeleteModal from "@/components/DeleteModal";
import { userTypeEnum, getWxAgentConfig } from "@/global";

export default {
  name: "OrderIndex",

  props: {
    noSearch: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
  },

  components: {
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    ARangePicker: DatePicker.RangePicker,

    DownloadOutlined,
    OrderDetail,
    DeleteModal,
  },

  data() {
    return {
      orderDetail: {
        corpId: 0,
        cover: "",
        goodsId: 0,
        specsId: 0,
        goodsName: "",
        customerType: 1,
        customerName: "",
        customerAvatar: "",
        customerMobile: "",
        orderSn: "",
        orderStatus: "",
        isPaid: 0,
        createdTime: 0,
        payTime: 0,
        summary: "",
        payAmount: 0,
        staffName: "",
        staffAvatar: "",
        corpTags: [],
      },

      deleteModalVisible: false,
      deleteItem: {},

      editRemarkVisible: false,
      editRemarkInfo: {
        id: undefined,
        remark: "",
      },
    };
  },
  setup(props) {
    const timeValue = ref([]);
    const orderDetailVisible = ref(false);
    const confirmLoading = ref(false);

    const searchForm = reactive({
      orderStatus: "",
      startTime: "",
      endTime: "",
      staffName: "",
      customerRealName: "",
      customerMobile: "",
    });

    watch(
      () => props.form,
      (value) => {
        const trulyValues = _.omitBy(value, _.isUndefined);
        _.assign(searchForm, trulyValues);
      },
      { deep: true, immediate: true }
    );

    const sumAmount = ref(0);
    const columns = [
      {
        title: "订单编号",
        dataIndex: "orderSn",
        width: 220,
        fixed: "left",
      },
      {
        title: "客户",
        dataIndex: "customerName",
        slots: { customRender: "customer" },
      },
      {
        title: "所属成员",
        dataIndex: "staffWorkWechatThirdAppUserId",
        slots: { customRender: "staffWorkWechatThirdAppUserId" },
        width: 150,
      },
      {
        title: "订单价格(元)",
        dataIndex: "payAmount",
        width: 130,
      },
      {
        title: "原价(元)",
        dataIndex: "priceAmount",
        width: 130,
      },
      {
        title: "商品名称",
        dataIndex: "goodsName",
      },

      {
        title: "订单状态",
        dataIndex: "orderStatus",
        slots: { customRender: "status" },
        width: 130,
      },
      {
        title: "用户协议",
        dataIndex: "protocolName",
        slots: { customRender: "protocol" },
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        width: 120,
        slots: { customRender: "createdTime" },
      },
      {
        title: "支付时间",
        dataIndex: "payTime",
        width: 120,
        slots: { customRender: "payTime" },
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: 180,
        slots: { customRender: "action" },
      },
    ];

    const ChangeOrderStatus = async () => {
      onSearchSubmit();
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
    };

    const handleOrderType = async (value) => {
      searchForm.orderType = value;
      onSearchSubmit();
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
    };

    const handleSetectTime = async (res) => {
      if (res.length > 0) {
        searchForm.startTime = dayjs(res[0]._d).startOf("date").valueOf();
        searchForm.endTime = dayjs(res[1]._d).endOf("date").valueOf();
      } else {
        searchForm.startTime = "";
        searchForm.endTime = "";
      }

      onSearchSubmit();
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
    };

    const searchOrder = _.debounce(async () => {
      onSearchSubmit();
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
    }, 500);

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, orderApi.search);

    const orderIndexRef = ref();
    const protocolModalVisible = ref(false);
    const protocolDetail = reactive({
      name: "",
      protocol: "",
    });
    async function openProtocolModal(id) {
      protocolModalVisible.value = true;
      const res = await orderApi.getProtocol({ id: id });
      _.assign(protocolDetail, res);
    }

    getWxAgentConfig();

    return {
      userTypeEnum,
      orderDetailVisible,
      confirmLoading,
      searchForm,
      sumAmount,
      handleOrderType,
      handleSetectTime,
      columns,
      orders: rows,
      timeValue,
      dayjs,
      searchOrder,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      ChangeOrderStatus,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      orderIndexRef,
      protocolModalVisible,
      protocolDetail,
      openProtocolModal,
    };
  },

  computed: {
    role() {
      const user = JSON.parse(window.localStorage.getItem("auth_user"));
      return user.role;
    },
  },
  async mounted() {
    this.sumAmount = await orderApi.sumPayAmount(this.searchForm);
  },
  methods: {
    async deleteOrder(deleteItem) {
      await orderApi.delete(deleteItem.id);
      this.deleteModalVisible = false;
      this.$message.success("删除订单成功");
      this.fetchPaginationData();
      this.sumAmount = await orderApi.sumPayAmount(this.searchForm);
    },
    async showOrderDetail(orderId) {
      this.orderDetail = await orderApi.getOrder({ orderId: orderId });
      this.orderDetailVisible = true;
    },
    openEditRemarkModal(order) {
      this.editRemarkVisible = true;
      this.editRemarkInfo.id = order.id;
      this.editRemarkInfo.remark = order.remark || "";
    },
    // 订单备注相关方法
    async editRemark() {
      this.confirmLoading = true;
      await orderApi.editRemark(this.editRemarkInfo);
      this.$message.success("修改成功");
      this.fetchPaginationData();
      this.confirmLoading = false;
      this.editRemarkVisible = false;
    },
    // 导出账单
    async exportOrder() {
      let url =
        "https://" + document.domain + "/api/console/order/exportOrders?";

      const params = {
        token: window.localStorage.getItem("auth_token") || "",
      };

      const pickedForm = _.pickBy(this.searchForm, (item) => {
        return item !== undefined;
      });
      Object.assign(params, pickedForm);

      for (var item in params) {
        url += `${item}=${params[item]}&`;
      }
      url = url.slice(0, url.length - 1);

      window.location.href = url;
    },
  },
};
</script>
<style lang='less' scoped>
.is-flex {
  display: flex;
  justify-content: flex-start;
}

.edit-remark {
  .ant-form-explain {
    position: absolute;
    bottom: @spacing-3x;
  }

  .ant-form-item-label {
    line-height: unset;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }

  .ant-input {
    height: 100px;
  }
}

.protocol-modal {
  .protocol__label {
    width: 70px;
    margin-right: 16px;
    color: @gray-dark;
    .text-14();
  }

  .protocol__value {
    color: @gray-darker;
  }

  .protocol-content {
    padding: 16px;
    width: 440px;
    height: 240px;
    overflow-y: auto;
    background-color: @bg-normal;
  }
}

.ant-btn-link {
  padding: 0;
  span {
    .text-overflow();
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    padding: 0 24px;
    height: 36px;
    color: @gray-dark;
    line-height: 36px;
    border-color: @border;

    &-checked {
      color: #fff;
      background-color: @color-primary;
      border-color: @color-primary;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
