import { reactive, ref, onMounted } from 'vue';
import _ from "lodash";
import { onBeforeRouteUpdate } from 'vue-router';

export default function usePaginationQuery(router, searchForm, searchMethod) {

    const rows = ref([]);
    const tableLoading = ref(false)

    const pagination = reactive({
        current: 1,
        pageSize: 20,
        total: 0,
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        showTotal: (total) => `共 ${total} 条`,
        showQuickJumper: true,
    });

    const pullQueryParams = (query) => {

        if (query.page) {
            pagination.current = _.toInteger(query.page);
        }

        if (query.size) {
            pagination.pageSize = _.toInteger(query.size);
        }
    }

    const buildQueryParams = () => {
        return {
            ...searchForm,
            page: pagination.current - 1,
            size: pagination.pageSize
        }
    }

    const fetchPaginationData = async () => {
        tableLoading.value = true
        const response = await searchMethod(buildQueryParams());
        rows.value = response.data;
        pagination.total = response.total;
        tableLoading.value = false
    }

    const onPaginationChange = async (pagination, filters, sorter) => {

        let sortParams = {};
        if (sorter.field && sorter.order) {
            sortParams = { sort: sorter.field + ',' + (sorter.order === 'ascend' ? 'asc' : 'desc') }
        } else {
            sortParams = { sort: '' }
        }

        const route = router.currentRoute.value;
        const query = _.assign({}, route.query, { page: pagination.current, size: pagination.pageSize }, sortParams);
        await router.push({ name: route.name, query: query });
    }

    const onSearchSubmit = async () => {
        pagination.current = 1;

        const route = router.currentRoute.value;
        const query = _.assign({}, route.query, searchForm, { page: _.toString(pagination.current), size: _.toString(pagination.pageSize) });

        if (_.isEqual(route.query, query)) {
            await fetchPaginationData();
        } else {
            await router.push({ name: route.name, query: query });
        }
    };

    onMounted(async () => {
        pullQueryParams(router.currentRoute.value.query);
        await fetchPaginationData();
    });

    onBeforeRouteUpdate(async (to, from, next) => {
        pullQueryParams(to.query);
        await fetchPaginationData();
        next()
    })

    return {
        rows,
        pagination,
        pullQueryParams,
        fetchPaginationData,
        onPaginationChange,
        onSearchSubmit,
        tableLoading
    }
}
