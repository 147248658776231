<template>
  <a-modal v-model:visible="modalVisible"
           :title="title"
           width="400px"
           @ok="handleOk"
           @cancel="$emit('update:visible', false)">
    <span class="gray">{{desc || '删除后无法恢复，确认删除？'}}</span>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
export default defineComponent({
  name: "DeleteModal",

  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "删除",
    },
    deleteItem: [Object, String],
    desc: String,
  },

  emits: ["update:visible", "delete"],

  components: {},

  setup(props, { emit }) {
    const modalVisible = ref(false);
    watch(
      () => props.visible,
      (newVisible) => {
        modalVisible.value = newVisible;
      }
    );

    function handleOk() {
      emit("delete", props.deleteItem);
    }

    return {
      modalVisible,
      handleOk,
    };
  },
});
</script>
<style lang='less' scoped>
</style>